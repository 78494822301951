export default function () {
    const select = document.querySelectorAll('[data-js-hook="site-select"]');

    if (select) {
        select.forEach((select) => {
            select.addEventListener('change', (e) => {
                window.location.href = e.target.value;
            });
        });
    }
}
